import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
} from "../utils/hooks";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import {
  GearIcon,
  AddCircleIcon,
  CreateInvoiceIcon,
  NoSelectedItemIcon,
  UserSolidIcon,
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
  CustomerAccountSelectIcon,
  CreditMemoSelectIcon,
  EditIcon,
  DeleteIcon,
  DropdownCloseIcon,
} from "./Icons";
import { useMutation, useQuery } from "react-query";
import { initialGeneralSettings, nanoid } from "../utils/helpers";
import Select from "react-select";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import eventBus from "../utils/EventBus";
import NumberCustomInput from "./utils/NumberCustomInput";
import { durationOptions } from "../config";
import ConfirmDialog from "./ConfirmDialogue";
import ModalLoader from "./utils/ModalLoader";

const inventoryManagementOptions = [
  {
    label: "FIFO",
    value: "FIFO",
  },
  {
    label: "LIFO",
    value: "LIFO",
  },
  {
    label: "FEFO",
    value: "FEFO",
  },
];

export default function GeneralSettingsModal({
  showGeneralSettingsModal,
  setShowGeneralSettingsModal,
}) {
  const paymentMethod = [
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit",
      value: "Credit",
    },
    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
    {
      icon: <CustomerAccountSelectIcon />,
      label: "Customer Account",
      value: "Customer Account",
    },
    {
      icon: <CashSelectIcon />,
      label: "Loyalty Account",
      value: "Loyalty Account",
    },
    {
      icon: <CreditMemoSelectIcon />,
      label: "Credit Memo",
      value: "Credit Memo",
    },
    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CashSelectIcon />,
      label: "Split Payment",
      value: "Split Payment",
    },
    {
      icon: <CashSelectIcon />,
      label: "Split Payment Multiple",
      value: "Split Payment Multiple",
    },
  ];
  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  //const generalSettings = useStoreState((state) => state.generalSettings);
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const [
    showJournalIncentiveAccountPopover,
    setShowJournalIncentiveAccountPopover,
  ] = useState(false);
  const [key, setkey] = useState();

  const addSettings = async (payload) => {
    let response = await fetch(`${backendUrl}/api/users/company-settings`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addSettingsMutation = useMutation((payload) => addSettings(payload), {
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: initialGeneralSettings,
    onSubmit: (values) => {
      //  Save  to  server
      addSettingsMutation.mutate(
        { settings: values },
        {
          onSuccess: ({ message, data }) => {
            setGeneralSettings(values);
            toast.success("Settings saved");
            setShowGeneralSettingsModal(false);
          },
        }
      );
    },
  });

  const setUp = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/company/settings`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.settings) {
      formik.setValues({ ...initialGeneralSettings, ...data.settings });
    }
    if (data.staff) {
      data.staff = data.staff.map((el) => ({
        ...el,
        label: el.Name,
        value: el.Staff_ID,
      }));
    }

    return data;
  };

  const {
    data = {
      staff: [],
    },
    isFetching,
  } = useQuery(["GET_GLOBAL_SETTINGS"], () => setUp(), {
    enabled: true,
  });

  const handleSelectedJornalAccount = (account) => {
    formik.setFieldValue("drumAccountID", account?.AccountID);
    formik.setFieldValue("drumAccountDescription", account?.Description);
    setShowJournalPopover(false);
  };

  eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", (account) => {
    handleSelectedJornalAccount(account);
  });

  const handleSelectedJornalIncentiveAccount = (account) => {
    formik.setFieldValue("incentiveAccountID", account?.AccountID);
    formik.setFieldValue("incentiveAccountDescription", account?.Description);
    setShowJournalIncentiveAccountPopover(false);
  };

  eventBus.useCustomEventListener(
    "JOURNAL_INCENTIVE_ACCOUNT_CREATED",
    (account) => {
      handleSelectedJornalIncentiveAccount(account);
    }
  );

  const uploadSignatureApi = async ({ file, index }) => {
    const formData = new FormData();
    formData.append("signature", file, `invoice_signature`);
    formData.append("index", index);

    let response = await fetch(
      `${backendUrl}/api/company/upload-invoice-signature`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const uploadSignatureApiMutation = useMutation(
    (payload) => uploadSignatureApi(payload),
    {
      onSuccess: () => {
        toast.success("Uploaded");
        setkey(nanoid());
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const removeSignatureApi = async ({ index }) => {
    let response = await fetch(
      `${backendUrl}/api/company/remove-invoice-signature/${index}`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ index }),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const removeSignatureApiMutation = useMutation(
    (payload) => removeSignatureApi(payload),
    {
      onSuccess: () => {
        toast.success("Removed");
        setkey(nanoid());
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const uploadSignature = (file, index) => {
    console.log(index);
    uploadSignatureApiMutation.mutate({ file, index });
  };

  const removeSignature = async (index) => {
    if (
      await ConfirmDialog({
        title: "Remove Signature",
      })
    ) {
      removeSignatureApiMutation.mutate({ index });
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={showGeneralSettingsModal}
        onHide={() => setShowGeneralSettingsModal(false)}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <GearIcon /> Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form
              noValidate
              onSubmit={formik.handleSubmit}
              autoComplete="off"
              style={
                isFetching ? { opacity: "0.5", pointerEvents: "none" } : {}
              }
            >
              <h2 className="mb-3 h5">Personal</h2>
              <Form.Group>
                <Form.Check
                  inline
                  id="convertToTonsAndPcs"
                  label="Convert Quantity to Tons and pcs"
                  name="convertToTonsAndPcs"
                  type={"checkbox"}
                  checked={formik.values.convertToTonsAndPcs}
                  onChange={formik.handleChange}
                />
              </Form.Group>

              {isAdmin ? (
                <div className="row border-top my-4 pt-4">
                  <h2 className="mb-3 h5">All Users</h2>
                  <div className="col-md-6">
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="enableCreditLimit"
                        label="Enable Customers Credit Limit"
                        name="enableCreditLimit"
                        type={"checkbox"}
                        checked={formik.values.enableCreditLimit}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="enableCreditPaymentForWalkIn"
                        label="Allow Credit Invoice for Walk-In Customer"
                        name="enableCreditPaymentForWalkIn"
                        type={"checkbox"}
                        checked={formik.values.enableCreditPaymentForWalkIn}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Invoice workflow</Form.Label>

                      <div>
                        <Form.Check
                          inline
                          label="Proforma Invoice"
                          value="Proforma Invoice"
                          name="pendingInvoiceType"
                          type={"radio"}
                          id="Proforma Invoice"
                          checked={
                            formik.values.pendingInvoiceType ===
                            "Proforma Invoice"
                          }
                          onChange={formik.handleChange}
                        />
                        <Form.Check
                          inline
                          label="Quotation"
                          value="Quotation"
                          name="pendingInvoiceType"
                          type={"radio"}
                          id="Quotation"
                          checked={
                            formik.values.pendingInvoiceType === "Quotation"
                          }
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Form.Group>
                    {/* <Form.Group className="mb-3">
                  <Form.Label className="mb-1 text-nowrap">Currency</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    isSearchable={false}
                     value={didYouKnowOptions.find(
                      (el) => el.value === formik.values.How_Did_Know
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("How_Did_Know", value)
                    }
                    options={didYouKnowOptions}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group> */}
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="hideDiscountColumn"
                        label="Hide Discount Column on Invoice"
                        name="hideDiscountColumn"
                        type={"checkbox"}
                        checked={formik.values.hideDiscountColumn}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="addItemDescriptionOnInvoice"
                        label="Show Item Description on Invoice"
                        name="addItemDescriptionOnInvoice"
                        type={"checkbox"}
                        checked={formik.values.addItemDescriptionOnInvoice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Unit Price Column name on Invoice</Form.Label>
                      <Form.Control
                        id="unitPriceTextOnInvoice"
                        name="unitPriceTextOnInvoice"
                        value={formik.values.unitPriceTextOnInvoice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="canAddBatchName"
                        label="Can Add Batch Name"
                        name="canAddBatchName"
                        type={"checkbox"}
                        checked={formik.values.canAddBatchName}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Batch Name Column name</Form.Label>
                      <Form.Control
                        id="Batch_Name"
                        name="Batch_Name"
                        value={formik.values.Batch_Name}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="showBatchNameOnInvoice"
                        label="Show Batch Name on Invoice"
                        name="showBatchNameOnInvoice"
                        type={"checkbox"}
                        checked={formik.values.showBatchNameOnInvoice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="linkPaymentToInvoice"
                        label="Link Payment To Invoice"
                        name="linkPaymentToInvoice"
                        type={"checkbox"}
                        checked={formik.values.linkPaymentToInvoice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="balanceInExpenseFromAccount"
                        label="Expense should be posted from account with balance"
                        name="balanceInExpenseFromAccount"
                        type={"checkbox"}
                        checked={formik.values.balanceInExpenseFromAccount}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="canBackDate"
                        label="Can Back Date"
                        name="canBackDate"
                        type={"checkbox"}
                        checked={formik.values.canBackDate}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <hr />
                    <h2 className="mb-3 h6">Drums Account</h2>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="enableDrumAccount"
                        label="Enable Drum Account"
                        name="enableDrumAccount"
                        type={"checkbox"}
                        checked={formik.values.enableDrumAccount}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Cost Per Drum</Form.Label>
                      <CurrencyCustomInput
                        id="costPerDrum"
                        name="costPerDrum"
                        value={formik.values.costPerDrum}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Drum Account</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          name="account"
                          placeholder="Select Drum Account"
                          value={`${formik.values?.drumAccountID ||
                            "..."} / ${formik.values?.drumAccountDescription ||
                            "..."}`}
                          onChange={() => {}}
                          readOnly
                        />

                        <Popover
                          isOpen={showJournalPopover}
                          onClickOutside={() => setShowJournalPopover(false)}
                          content={() => (
                            <ExpensesSelector
                              handleSelectedExpense={
                                handleSelectedJornalAccount
                              }
                              usage={"chart of accounts"}
                              onHide={() => setShowJournalPopover(false)}
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() => setShowJournalPopover(true)}
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>
                    <hr />
                    <Form.Group className="mb-3">
                      <Form.Label>Store Name</Form.Label>
                      <Form.Control
                        id="storeName"
                        name="storeName"
                        value={formik.values.storeName}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Checkout Button Text</Form.Label>
                      <Form.Control
                        id="checkoutButtonText"
                        name="checkoutButtonText"
                        value={formik.values.checkoutButtonText}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Store Email</Form.Label>
                      <Form.Control
                        id="storeEmail"
                        name="storeEmail"
                        type="email"
                        value={formik.values.storeEmail}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="hideUnitPriceInStore"
                        label="Hide Unit Price In Store"
                        name="hideUnitPriceInStore"
                        type={"checkbox"}
                        checked={formik.values.hideUnitPriceInStore}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="customerCanEditPriceInCart"
                        label="Customer Can Edit Price in Cart"
                        name="customerCanEditPriceInCart"
                        type={"checkbox"}
                        checked={formik.values.customerCanEditPriceInCart}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="customerVerifiction"
                        label="Enable Customer Verification"
                        name="customerVerification"
                        type={"checkbox"}
                        checked={formik.values.customerVerification}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="disablePaymentAtCheckout"
                        label="Disable Payment at Checkout"
                        name="disablePaymentAtCheckout"
                        type={"checkbox"}
                        checked={formik.values.disablePaymentAtCheckout}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Convertable UOM Name</Form.Label>
                      <Form.Control
                        id="convertableUOM"
                        name="convertableUOM"
                        value={formik.values.convertableUOM}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Incentive Account</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          name="account"
                          placeholder="Select Incentive Account"
                          value={`${formik.values?.incentiveAccountID ||
                            "..."} / ${formik.values
                            ?.incentiveAccountDescription || "..."}`}
                          onChange={() => {}}
                          readOnly
                        />

                        <Popover
                          isOpen={showJournalIncentiveAccountPopover}
                          onClickOutside={() =>
                            setShowJournalIncentiveAccountPopover(false)
                          }
                          content={() => (
                            <ExpensesSelector
                              handleSelectedExpense={
                                handleSelectedJornalIncentiveAccount
                              }
                              usage={"chart of accounts"}
                              onHide={() =>
                                setShowJournalIncentiveAccountPopover(false)
                              }
                              dispatchKeyForCreatedAccount={
                                "JOURNAL_INCENTIVE_ACCOUNT_CREATED"
                              }
                            />
                          )}
                          position="bottom"
                        >
                          <InputGroup.Text
                            onClick={() =>
                              setShowJournalIncentiveAccountPopover(true)
                            }
                          >
                            <MagnifyIcon />
                          </InputGroup.Text>
                        </Popover>
                      </InputGroup>
                    </Form.Group>
                    <hr />{" "}
                    <Form.Group className="mb-3">
                      <Form.Label>Proforma Invoice Title</Form.Label>
                      <Form.Control
                        id="proformaInvoiceTitle"
                        name="proformaInvoiceTitle"
                        as={"textarea"}
                        placeholder="Enter Proforma Invoice Title"
                        value={formik.values.proformaInvoiceTitle}
                        onChange={formik.handleChange}
                        rows={3}
                      />{" "}
                    </Form.Group>
                  </div>

                  <div className="col-md-6">
                    {" "}
                    <h2 className="mb-3 h6">
                      Create/Update Item (Non Admin user){" "}
                    </h2>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="isPurchasePriceRequired"
                        label="Purchase Price is required"
                        name="isPurchasePriceRequired"
                        type={"checkbox"}
                        checked={formik.values.isPurchasePriceRequired}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="presetItemValues"
                        label="Preset Item UnitCost/Price in update item"
                        name="presetItemValues"
                        type={"checkbox"}
                        checked={formik.values.presetItemValues}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="presetUnitPrice"
                        label="Preset Unit Price in update item"
                        name="presetUnitPrice"
                        type={"checkbox"}
                        checked={formik.values.presetUnitPrice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>{" "}
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="hasfreightVendor"
                        label="Has Freight Vendor"
                        name="hasfreightVendor"
                        type={"checkbox"}
                        checked={formik.values.hasfreightVendor}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <hr />
                    <h2 className="mb-3 h6">Post Invoice (Non Admin user)</h2>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="hideUnitCost"
                        label="Hide Unit Cost"
                        name="hideUnitCost"
                        type={"checkbox"}
                        checked={formik.values.hideUnitCost}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="hideUnitPrice"
                        label="Hide Unit Price"
                        name="hideUnitPrice"
                        type={"checkbox"}
                        checked={formik.values.hideUnitPrice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="hideDiscount"
                        label="Hide Discount"
                        name="hideDiscount"
                        type={"checkbox"}
                        checked={formik.values.hideDiscount}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="presetFinalSellingPrice"
                        label="Preset Final Selling Price"
                        name="presetFinalSellingPrice"
                        type={"checkbox"}
                        checked={formik.values.presetFinalSellingPrice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="presetFinalSellingPrice"
                        label="Lock Final Selling Price"
                        name="lockFinalSellingPrice"
                        type={"checkbox"}
                        checked={formik.values.lockFinalSellingPrice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Payment method</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPlacement="top"
                        placeholder="Choose method"
                        isSearchable={false}
                        value={paymentMethod.find(
                          (el) =>
                            el.value === formik.values.defaultInvoicePaymentType
                        )}
                        options={paymentMethod}
                        onChange={(selected) =>
                          formik.setFieldValue(
                            "defaultInvoicePaymentType",
                            selected?.value
                          )
                        }
                        getOptionLabel={(el) => (
                          <div className="label-with-icon d-flex gap-2 align-items-center">
                            <span>{el.icon}</span>{" "}
                            <span className="fw-5">{el.label}</span>
                          </div>
                        )}
                        isClearable
                      />
                    </Form.Group>
                    <hr />
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="supplyNow"
                        label="Supply Now"
                        name="supplyNow"
                        type={"checkbox"}
                        checked={formik.values.supplyNow}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="lockItemsInSupplyCenter"
                        label="Lock Items In Supply Center"
                        name="lockItemsInSupplyCenter"
                        type={"checkbox"}
                        checked={formik.values.lockItemsInSupplyCenter}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="lockApprovedItemsInProforma"
                        label="Lock Approved Items in Proforma"
                        name="lockApprovedItemsInProforma"
                        type={"checkbox"}
                        checked={formik.values.lockApprovedItemsInProforma}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="createInvoiceAfterRestock"
                        label="Create Invoice after Restock"
                        name="createInvoiceAfterRestock"
                        type={"checkbox"}
                        checked={formik.values.createInvoiceAfterRestock}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="selectBatchDuringInvoicing"
                        label="Select Batch During Invoicing"
                        name="selectBatchDuringInvoicing"
                        type={"checkbox"}
                        checked={formik.values.selectBatchDuringInvoicing}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="selectBatchWhenIssuingItem"
                        label="Select Batch when Issuing Item"
                        name="selectBatchWhenIssuingItem"
                        type={"checkbox"}
                        checked={formik.values.selectBatchWhenIssuingItem}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="withInvoiceCatBalance"
                        label="Show Invoice Cat Balance on Invoice"
                        name="withInvoiceCatBalance"
                        type={"checkbox"}
                        checked={formik.values.withInvoiceCatBalance}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="isFarm"
                        label="Farm"
                        name="isFarm"
                        type={"checkbox"}
                        checked={formik.values.isFarm}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Inventory Selector</Form.Label>

                      <div>
                        <Form.Check
                          inline
                          label="Single"
                          value="Single"
                          name="inventorySelector"
                          type={"radio"}
                          id="Single"
                          checked={formik.values.inventorySelector === "Single"}
                          onChange={formik.handleChange}
                        />
                        <Form.Check
                          inline
                          label="Multiple"
                          value="Multiple"
                          name="inventorySelector"
                          type={"radio"}
                          id="Multiple"
                          checked={
                            formik.values.inventorySelector === "Multiple"
                          }
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Inventory Management</Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        options={inventoryManagementOptions}
                        value={inventoryManagementOptions.find(
                          (el) => el.value === formik.values.inventoryManagement
                        )}
                        onChange={(selected) =>
                          formik.setFieldValue(
                            "inventoryManagement",
                            selected?.value
                          )
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="addDriverInfoBeforeSupply"
                        label="Add Driver Info Before Supply"
                        name="addDriverInfoBeforeSupply"
                        type={"checkbox"}
                        checked={formik.values.addDriverInfoBeforeSupply}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <hr />
                    <h2 className="mb-3 h6">Requires Admin Approval</h2>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="expenseRequiresAdminApproval"
                        label="Expense"
                        name="expenseRequiresAdminApproval"
                        type={"checkbox"}
                        checked={formik.values.expenseRequiresAdminApproval}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="editVendorPaymentRequiresAdminApproval"
                        label="Edit Vendor Payment"
                        name="editVendorPaymentRequiresAdminApproval"
                        type={"checkbox"}
                        checked={
                          formik.values.editVendorPaymentRequiresAdminApproval
                        }
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="deleteVendorPaymentRequiresAdminApproval"
                        label="Delete Vendor Payment"
                        name="deleteVendorPaymentRequiresAdminApproval"
                        type={"checkbox"}
                        checked={
                          formik.values.deleteVendorPaymentRequiresAdminApproval
                        }
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="editJournalRequiresAdminApproval"
                        label="Edit Journal Entry"
                        name="editJournalRequiresAdminApproval"
                        type={"checkbox"}
                        checked={formik.values.editJournalRequiresAdminApproval}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="itemStatusSettings"
                        label="Show Processing Type"
                        name="itemStatusSettings"
                        type={"checkbox"}
                        checked={formik.values.itemStatusSettings}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="restockRequiresApproval"
                        label="Restock Requires Approval"
                        name="restockRequiresApproval"
                        type={"checkbox"}
                        checked={formik.values.restockRequiresApproval}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <div>
                      <Form.Group className="mb-3 pb-2">
                        <Form.Label>Item Expiry Filter Duration</Form.Label>

                        <Row>
                          <Col>
                            <NumberCustomInput
                              placeholder="0"
                              name="expiryFilterDurationNumber"
                              value={formik.values.expiryFilterDurationNumber}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value, true);
                              }}
                              // isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                            />
                          </Col>
                          <Col>
                            <Select
                              classNamePrefix={"form-select"}
                              placeholder={""}
                              value={durationOptions.find(
                                (el) =>
                                  el.value ===
                                  formik.values.expiryFilterDurationValue
                              )}
                              options={durationOptions}
                              onChange={({ value }) =>
                                formik.setFieldValue(
                                  "expiryFilterDurationValue",
                                  value
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </div>
                    <div>
                      <hr />
                      <h2 className="mb-3 h6">Procurement Planning</h2>
                      <Form.Group className="mb-3 pb-2">
                        <Form.Label>
                          Analyze Data for planning from:{" "}
                        </Form.Label>
                        <Row>
                          <Col>
                            <NumberCustomInput
                              placeholder="0"
                              name="analysedataFrom"
                              value={formik.values.analyseDataFrom}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value, true);
                              }}
                            />
                          </Col>
                          <Col>
                            <p className="fw-bold mt-3">Months</p>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3 pb-2">
                        <Form.Label>Plan For</Form.Label>
                        <Row>
                          <Col>
                            <NumberCustomInput
                              placeholder="0"
                              name="forcastDataFor"
                              value={formik.values.forcastDataFor}
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value, true);
                              }}
                            />
                          </Col>
                          <Col>
                            <p className="fw-bold mt-3">Months</p>
                          </Col>
                        </Row>
                      </Form.Group>
                      <hr />
                      <Form.Group className="mb-3">
                        <Form.Label className="d-flex justify-content-between">
                          <span>TAX</span> <span> Percentage(%)</span>
                        </Form.Label>

                        <FieldArray
                          name="taxOptions"
                          render={(arrayHelpers) => (
                            <>
                              <div className="row">
                                {formik.values?.taxOptions &&
                                  formik.values?.taxOptions.map((el, index) => (
                                    <div className="d-flex gap-3 mb-2">
                                      <Form.Control
                                        name={`taxOptions[${index}].name`}
                                        placeholder="Name"
                                        value={
                                          formik.values.taxOptions[index].name
                                        }
                                        onChange={formik.handleChange}
                                      />
                                      <Form.Control
                                        type="number"
                                        name={`taxOptions[${index}].percentage`}
                                        placeholder="Percentage"
                                        value={
                                          formik.values.taxOptions[index]
                                            .percentage
                                        }
                                        onChange={formik.handleChange}
                                      />
                                      <Button
                                        title="Remove"
                                        variant=""
                                        type="button"
                                        size="xs"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        ✖
                                      </Button>
                                    </div>
                                  ))}
                              </div>
                              <div className="d-flex justify-content-start px-4">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-primary text-nowrap"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      department: "",
                                      jobGrade: "",
                                      staff: "",
                                    })
                                  }
                                >
                                  + Add
                                </button>
                              </div>
                            </>
                          )}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <hr />
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="addCustomerSignatureOnInvoice"
                        label="Add Customer Signature to Quote"
                        name="addCustomerSignatureOnInvoice"
                        type={"checkbox"}
                        checked={formik.values.addCustomerSignatureOnInvoice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        inline
                        id="addSignatureOnInvoice"
                        label="Add Signature to Invoice"
                        name="addSignatureOnInvoice"
                        type={"checkbox"}
                        checked={formik.values.addSignatureOnInvoice}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Check
                        id="enableInvoiceSignatory"
                        name="enableInvoiceSignatory"
                        label="Enable Invoice Signatory"
                        type={"checkbox"}
                        checked={formik.values.enableInvoiceSignatory}
                        onChange={formik.handleChange}
                      />
                    </Form.Group>
                    {formik.values.enableInvoiceSignatory && (
                      <FieldArray
                        name="invoiceSignatories"
                        render={(arrayHelpers) => (
                          <>
                            <div className="row">
                              {formik.values.invoiceSignatories.map(
                                (el, index) => (
                                  <div className="col-6 position-relative">
                                    <div className="d-flex justify-content-end">
                                      <Button
                                        title="Remove"
                                        variant="outline-danger"
                                        type="button"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        ✖
                                      </Button>
                                    </div>

                                    <Form.Group className="mb-3">
                                      <Form.Label>
                                        Invoice Signatory Name
                                      </Form.Label>
                                      <Form.Control
                                        id="invoiceSignatoryStaffName"
                                        name={`invoiceSignatories[${index}]invoiceSignatoryStaffName`}
                                        placeholder="Enter Invoice Signatory Name"
                                        value={
                                          formik.values.invoiceSignatories[
                                            index
                                          ].invoiceSignatoryStaffName
                                        }
                                        onChange={formik.handleChange}
                                      />

                                      {/* <Select
                      classNamePrefix={"form-select"}
                      name="invoiceSignatoryStaff_ID"
                      options={data?.staff}
                      value={data?.staff.find(
                        (el) =>
                          el.Staff_ID === formik.values.invoiceSignatoryStaff_ID
                      )}
                      onChange={(selected) =>
                        formik.setFieldValue(
                          "invoiceSignatoryStaff_ID",
                          selected?.value
                        )
                      }
                      isClearable
                      menuP 
                    lacement="top" />*/}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                      <Form.Label>
                                        {" "}
                                        Invoice Signatory Title
                                      </Form.Label>
                                      <Form.Control
                                        id="invoiceSignatoryStaffTitle"
                                        name={`invoiceSignatories[${index}]invoiceSignatoryStaffTitle`}
                                        placeholder="Enter Invoice Signatory Title"
                                        value={
                                          formik.values.invoiceSignatories[
                                            index
                                          ].invoiceSignatoryStaffTitle
                                        }
                                        onChange={formik.handleChange}
                                      />{" "}
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                      <Form.Label>
                                        Upload Invoice Signature
                                      </Form.Label>
                                      <br />
                                      <Form.Label className="btn btn-light-blue">
                                        <span>
                                          Select Image{" "}
                                          <i>
                                            {formik.values.invoiceSignatories[
                                              index
                                            ].isLoading
                                              ? "Uploading..."
                                              : ""}
                                          </i>
                                        </span>
                                        <Form.Control
                                          type="file"
                                          className="d-none"
                                          accept="image/*"
                                          multiple
                                          onChange={(e) =>
                                            uploadSignature(
                                              e.target.files[0],
                                              index
                                            )
                                          }
                                        />
                                      </Form.Label>
                                      <div>
                                        <img
                                          key={key}
                                          style={{
                                            width: "10rem",
                                          }}
                                          alt=""
                                          src={`${backendUrl}/api/company/invoice-signature/${index}?key=${key}`}
                                        />{" "}
                                      </div>{" "}
                                      <Button
                                        variant=""
                                        title="Remove Signature"
                                        style={{ left: 0 }}
                                        onClick={() => removeSignature(index)}
                                      >
                                        <DropdownCloseIcon /> Remove Image
                                      </Button>
                                    </Form.Group>
                                  </div>
                                )
                              )}{" "}
                            </div>

                            <div className="d-flex justify-content-start px-4">
                              <button
                                type="button"
                                className="btn btn-sm btn-primary text-nowrap"
                                onClick={() =>
                                  arrayHelpers.push({
                                    invoiceSignatoryStaffName: "",
                                    invoiceSignatoryStaffTitle: "",
                                    invoiceSignatoryStaffImage: "",
                                  })
                                }
                              >
                                + Add Invoice Signatory
                              </button>
                            </div>
                          </>
                        )}
                      />
                    )}
                  </div>
                </div>
              ) : null}
            </Form>
          </FormikProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => formik.submitForm()}
            variant="primary"
            disabled={addSettingsMutation.isLoading}
            className="px-4"
          >
            {addSettingsMutation.isLoading ? "Please wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalLoader
        show={
          removeSignatureApiMutation.isLoading ||
          uploadSignatureApiMutation.isLoading
        }
      />
    </>
  );
}
