import { useState, useRef, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";

export function InventoryNav(props) {
  const [items] = useState([
    {
      name: "Inventory Entry",
      to: "inventory-entry",
    },
    {
      name: "Stock Count",
      to: "stock-counts",
    },
    {
      name: "Item Category",
      to: "category",
    },
    {
      name: "Item Manufactuer",
      to: "manufactuer",
    },
    {
      name: "Item Issuer",
      to: "item-issuer",
    },
    /* {
      name: "Branches Inventory",
      to: "branches-inventory",
    }, */
    {
      name: "Vendor",
      to: "vendor",
    },
    {
      name: "Manage Transaction",
      to: "manage-transaction",
    },

    {
      name: "Item Manager",
      to: "inventory-manager",
    },
    {
      name: "Item Conversion",
      to: "item-conversion",
    },
    {
      name: "Processing",
      to: "processing-manager",
    },
    {
      name: "Items Below Reorder Level",
      to: "items-out-of-stock",
    },
    {
      name: "Items Expiry Report",
      to: "items-expiry-report",
    },
    {
      name: "Shipping Cost",
      to: "shopping-cost",
    },
    {
      name: "Procurement Plan",
      to: "items-forcast-recorder",
    },
    {
      name: "Storage Location",
      to: "storage-location",
    },
    {
      name: "Expected Items Report",
      to: "items-expected-report",
    },
  ]);

  const fileHolder = useRef(null);
  const [isShowLeftSroll, setShowLeftScroll] = useState(false);
  const [isShowRightSroll, setShowRightScroll] = useState(false);

  const showLeftSroll = () => {
    setShowLeftScroll(fileHolder?.current && fileHolder.current.scrollLeft > 0);
  };

  const showRightScroll = () => {
    setShowRightScroll(
      fileHolder.current &&
        fileHolder.current.offsetWidth + fileHolder.current.scrollLeft <
          fileHolder.current.scrollWidth
    );
  };

  const srcollFileHolder = () => {
    showLeftSroll();
    showRightScroll();
  };

  useEffect(() => {
    setTimeout(() => {
      if (fileHolder.current) srcollFileHolder();
    }, 50);
  }, [fileHolder]);

  return (
    <div className=" tabs-holder">
      {isShowLeftSroll && (
        <button
          className="btn slider-btn left"
          onClick={() => (fileHolder.current.scrollLeft -= 500)}
        >
          <ChevronLeftIcon />
        </button>
      )}
      <div
        ref={fileHolder}
        onScroll={(e) => srcollFileHolder(e)}
        className="dashboard-tabs innerpage-tabs px-4"
      >
        <ul className="nav nav-tabs">
          {items.map((item, index) => (
            <li key={index} className="nav-item">
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>{" "}
      {isShowRightSroll && (
        <button
          className="btn slider-btn right"
          onClick={() => (fileHolder.current.scrollLeft += 500)}
        >
          <ChevronRightIcon />
        </button>
      )}
    </div>
  );
}
